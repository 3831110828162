<template>
  <div class="header">
    <div class="container">
      <router-link :to="{ name: 'home' }"
        ><img src="/img/logo.svg"
      /></router-link>
      <div class="menu">
        <p>
          <router-link to="/#ancla1" @click="scrollSection('ancla1')"
            >Lorem ipsum
          </router-link>
        </p>
        <p>
          <router-link to="/#ancla2" @click="scrollSection('ancla2')"
            >Lorem ipsum
          </router-link>
        </p>
        <p>
          <router-link :to="{ name: 'list' }">Tablón de Anuncios </router-link>
        </p>
        <p><a href="https://www.google.es" target="_blank">Lorem ipsum </a></p>
        <!-- <a href="www.google.es">
          <p>BASES CONVOCATORIA</p>
        </a> -->
      </div>
    </div>
  </div>
  <div class="hamburguesa">
    <div v-if="activada">
      <a href="/"><img src="/img/logo.svg" /></a>
    </div>
    <div v-else>
      <a href="/"><img src="/img/logo.svg" /></a>
      <div class="menu">
        <p>
          <router-link to="/#ancla1" @click="scrollSection('ancla1')"
            >Lorem ipsum
          </router-link>
        </p>
        <p>
          <router-link to="/#ancla2" @click="scrollSection('ancla2')"
            >Lorem ipsum
          </router-link>
        </p>
        <p>
          <router-link :to="{ name: 'list' }">Tablón de Anuncios </router-link>
        </p>
        <p><a href="https://www.google.es" target="_blank">Lorem ipsum </a></p>
      </div>

    </div>

    <button @click="cambiarEstado()" v-if="!activada">
      <i class="bi bi-x"></i>
    </button>
    <button @click="cambiarEstado()" v-else><i class="bi bi-list"></i></button>
  </div>
</template>

<script>
export default {
  name: "headerWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      activada: false,
    };
  },
  methods: {
    cambiarEstado() {
      this.activada = !this.activada;
    },
    scrollSection: function (params) {
      this.cambiarEstado();
      setTimeout(function () {
        document.getElementById(params).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },
  },
  watch: {
    $route() {
      if (this.$route.hash) {
        let element = this.$route.hash.replace("#", "");
        setTimeout(function () {
          document.getElementById(element).scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 200);
      }
      this.activada = true
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
