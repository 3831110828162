<template lang="html">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
  <section class="footer-section">



    <div class="footer-enlaces">
      <div class="container">
        <div class="documentos">
          <a target="_blank" href=" ">Lorem ipsum </a>
          <a href="">Lorem ipsum </a>
          <a href="">Lorem ipsum </a>
        </div>

      </div>
    </div>
  </section>

</template>

<script lang="js">

  export default {
    name: 'footer',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }

 
</script>

<style scoped lang="scss">
  .footer {}
</style>
