import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home.vue"),
  },
  {
    path: "/tablon-de-anuncios",
    name: "list",
    component: () => import("@/views/list.vue"),
  },
  {
    path: "/oferta/:slug",
    name: "oferta",
    props: true,
    component: () => import("@/views/view-offer.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash, // Si tiene hash, navega al elemento con el ID del hash
        behavior: 'smooth'
      }
    } else {
      return { top: 0 };
    }
  },
  routes,
});

export default router;
